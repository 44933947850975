import React, { useState, useEffect } from "react";
import { auth } from "./firebase";
import Login from "./Login";
import SignUp from "./SignUp";
import Dashboard from "./Dashboard";
import {
  signInWithEmailAndPassword,
  onAuthStateChanged,
  sendEmailVerification,
} from "firebase/auth";

function App() {
  const [user, setUser] = useState(null);
  const [isSigningUp, setIsSigningUp] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [loading, setLoading] = useState(true); // 初期ローディング状態をtrueに設定
  const [error, setError] = useState(""); // エラーメッセージの追加

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setLoading(false); // ユーザー状態の確認が完了したらローディングをfalseに設定
      if (user) {
        setUser(user);
        setEmailVerified(user.emailVerified);
      } else {
        setUser(null);
        setEmailVerified(false);
      }
    });
    return () => unsubscribe();
  }, []);

  const handleLogin = async (email, password) => {
    setLoading(true);
    setError(""); // エラーメッセージをリセット
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      setUser(userCredential.user);
      setEmailVerified(userCredential.user.emailVerified);
      if (!userCredential.user.emailVerified) {
        alert(
          "メールアドレスが確認されていません。確認メールを再送信しました。メールを確認してください。"
        );
        await sendEmailVerification(userCredential.user);
      }
    } catch (error) {
      setError("メールアドレスまたはパスワードが正しくありません"); // エラーメッセージを設定
    } finally {
      setLoading(false);
    }
  };

  const handleSignUp = () => {
    setIsSigningUp(false);
  };

  return (
    <div className="App">
      {loading && (
        <div className="loading-overlay">
          <div className="loading-spinner">ログイン中...</div>
        </div>
      )}
      {!loading &&
        (user ? (
          emailVerified ? (
            <Dashboard user={user} />
          ) : (
            <div>
              <p>
                メールアドレスが確認されていません。メールを確認してください。
              </p>
              <button onClick={async () => await sendEmailVerification(user)}>
                確認メールを再送信
              </button>
              <button onClick={() => auth.signOut()}>ログアウト</button>
            </div>
          )
        ) : isSigningUp ? (
          <SignUp
            onSignUp={handleSignUp}
            onBackToLogin={() => setIsSigningUp(false)}
          />
        ) : (
          <Login
            onLogin={handleLogin}
            onSignUp={() => setIsSigningUp(true)}
            loading={loading}
            error={error}
          />
        ))}
    </div>
  );
}

export default App;
